import React, { useEffect } from "react";
import PropTypes from "prop-types";
/* Components */
import { Route, Routes } from "react-router-dom";
import HorizontalLayout from "./components/HorizontalLayout/";
/* Redux */
import { connect, useDispatch } from "react-redux";
import { userRoutes } from "./routes/allRoutes";
/* Helpers and Selectors */
import { selectEthSiweSign } from "ethereum-store/src/store/ethereum/selectors";
import { getLocalStorageByKey } from "./helpers/local_storage_helper";
/* Google analytics */
import ReactGA from "react-ga4";
/* Style */
import "./assets/scss/theme.scss";
import { chain } from "./config";

const Web3 = require("web3");
initializeReactGA();

const App = (props) => {
  let dispatch = useDispatch();
  const Layout = HorizontalLayout;

  useEffect(() => {
    let userAddress = props.userState.address ? Web3.utils.toChecksumAddress(props.userState.address) : undefined;
    let ls = getLocalStorageByKey(userAddress);
    if (userAddress && !selectEthSiweSign(props.ethState, [userAddress]) && ls) {
      let msg = ls ? ls.message : undefined;
      let sigHash = ls ? ls.signature : undefined;
      let mail = ls ? ls.email : undefined;
      let country = ls ? ls.country : undefined;
      let occupation = ls ? ls.occupation : undefined;
      dispatch({
        type: "SET_ETH_SIWE_SIGN",
        key: userAddress,
        signature: sigHash,
        message: msg,
        email: mail,
        country: country,
        occupation: occupation,
      });
    }
  }, [props, dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({ type: "ETH_DISPATCH_CLOCK" });
    }, 500);
    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: "SET_ALL_SETTINGS" });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: "SET_USER_CURRENT_CHAIN", name: chain.name, id: chain.id, rpc: chain.rpc });
  }, [dispatch]);

  return (
    <React.Fragment>
      <Routes>
        {userRoutes.map((route, idx) => (
          <Route
            path={route.path}
            key={idx}
            element={
              <Layout>
                <route.component />
              </Layout>
            }
            exact
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

function initializeReactGA() {
  if (process.env.REACT_APP_GA_TRACKING_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    ReactGA.send("pageview");
  }
}

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return { layout: state.Layout, userState: state.UserReducer, ethState: state.EthereumReducer };
};

export default connect(mapStateToProps, null)(App);
